.dashboard {
    & .ant-row {
        height: 100%;
    }
    & .height-55 {
        height: 55px !important;
    }
    & .main-table {
        list-style: none;
        position: relative;
        -webkit-transition: all .3s;
        transition: all .3s;
        padding: 10px;
        height: calc(100% - 0px);
        list-style: none;
        background: #fff;
        border-radius: 2px;
        position: relative;
        -webkit-transition: all .3s;
        -o-transition: all .3s;
        transition: all .3s;
        padding: 10px;
        height: calc(100% - 0px);
        background: #FFFFFF;
        box-shadow: 0 2px 9px 0 rgba(0,0,0,0.03);
        border-radius: 6px;
    }
    & .breadcrumb-div {
        padding: 20px;
        background: #fff;
    }
    & .contents-main {
        background: #f0f2f5;
    }
    & .main-table-div {
        padding: 10px;
        height: calc(100% - 65px);
    }
    & .add-zone-button {
        width: auto;
        float: right;
        margin-top: -27px;
    }
}
.zone-create {
    & .ant-row {
        background: #fff !important;
    }
}