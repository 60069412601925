.header {
    background: #fff;
    height: 70px;
    border-bottom: 1px solid #e8e8e8;
    padding: 0 12px 0 0;
    -webkit-box-shadow: 0 1px 4px rgba(0,21,41,.08);
    box-shadow: 0 1px 4px rgba(0,21,41,.08);
    position: relative;
    > .right {
        float: right;
        height: 100%;
        > .action {
            cursor: pointer;
            padding: 9px 25px;
            display: inline-block;
            -webkit-transition: all .3s;
            transition: all .3s;
            height: 100%;
            vertical-align: middle;
            line-height: 4;
        }
    }
    & .anticon {
        vertical-align: middle;
        font-size: 20px;
    }
    & .left {
        float: left;
        color: #4A4A4A;
        font-size: 24px;
        padding: 19px 25px;
    }
}
.user-dropdown-icon {
    margin-top: 4px;
    padding-right: 10px;
}
.user-edit {
    & .ant-row {
        background: #fff !important;
    }
}
button.ant-btn.edit-profile-cancel.ant-btn-default {
    margin-left: 25px;
}
.left-150 {
    margin-left: 150px;
}
.ant-row.ant-form-item.submit-buttons-edit-profile {
    margin-bottom: 0px;
}
.ant-calendar-picker-container {
    z-index: 99999 !important;
}
.ant-modal.selection-change .ant-modal-body {
    height: 300px;
    overflow: auto;
}
.user-avatar.sb-avatar__text {
    font-size: 14.5px !important;
}
.welcome-text {
    display: inline-block;
    padding-right: 15px;
    cursor: default;
}
.user-avatar.sb-avatar.sb-avatar--text {
    margin-top: -20px;
}
.user-welcome{
    height: 17.5px;
    margin-top: -10px;
}
.welcome-date {
    display: unset;    
    padding-right: 20px;
}
.user-initials {
    display: inline-block;
    height: 40px;
    width: 40px;
    text-align: center;
    border-radius: 50%;
    color: #fff;
    font-size: 14.5px;
    font-weight: 900;
    line-height: 40px;
    top: 13px;
    position: absolute;
    right: 10px;
    background-image: linear-gradient(-180deg, #001529 0%, #DB3069 100%) !important;
    cursor: pointer;
}