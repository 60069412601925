body {
  margin: 0px;
  height: 100%;
  width: 100%;
  background: #f0f2f5 !important;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%;
  position: relative;
  font-family: 'Raleway', sans-serif;
}
.height-100 {
    height: 100%;
}
.ant-input,.ant-form-item,.ant-checkbox-wrapper,.ant-menu,.ant-badge,.ant-table,.ant-select,.ant-breadcrumb,.ant-popover {
  font-family: 'Raleway', sans-serif !important;
}
.ant-menu-inline .ant-menu-item {
  margin-top: 0px !important;
}
.height-100-55 {
  height: calc(100% - 70px);
}
.cursor-pointer {
  cursor: pointer;
}
.ant-calendar-picker-container,.ant-popover.ant-popover-placement-top,.ant-message-notice {
  z-index: 99999 !important;
}
.ant-popover {
  z-index: 999 !important;
}
.ant-drawer {
  z-index: 999 !important;
}
.ant-table-thead > tr {
  background: #f2f2f2 !important;
}
.ant-table-thead > tr > th {
  border-bottom: 0px none !important;
}
.info, .success, .warning, .error, .validation {
  -webkit-animation: seconds 0s ease-in 3s forwards;
  -webkit-animation-iteration-count: 1;
  -webkit-animation-delay: 3s;
  -moz-animation: seconds 0s ease-in 3s forwards;
  -o-animation: seconds 0s ease-in 3s forwards;
  animation: seconds 0s ease-in 3s forwards;
  animation-iteration-count: 1;
  animation-delay: 3s;
  position: relative;
}
@-webkit-keyframes seconds {
  0% {
    opacity: 1;
    left:0;
  }
  100% {
    opacity: 0;
    // left: -9999px; 
    position: absolute;   
  }
}
@keyframes seconds {
  0% {
    opacity: 1;
    left:0;
  }
  100% {
    opacity: 0;
    // left: -9999px;
    position: absolute;     
  }
}
::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 7px;
}
::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: rgba(0, 0, 0, .5);
  -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, .5);
}
span.show-required::before {
  display: inline-block;
  margin-right: 4px;
  content: "*";
  font-family: SimSun;
  line-height: 1;
  font-size: 14px;
  color: #f5222d;
}