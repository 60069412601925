.sidebar {
    width:100%;
    border-right: 1px solid #e8e8e8;
    height: 100%;
    > .ant-menu-vertical {
        border-right: 0px none !important;
    }
    > .logo {
        height: 70px;
        text-align: center;
        // background: linear-gradient(-180deg, #F99F00 0%, #DB3069 100%) !important;
        // border-bottom: 1px solid #e8e8e8;
        // border-right: 1px solid #e8e8e8;
        > div {
            font-weight: 500;
            font-size: 20px;   
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
            text-align: center;
        }
    }
}
ul.ant-menu.ant-menu-light.ant-menu-root.ant-menu-inline {
    height: calc(100% - 70px);
    overflow: auto;
}
.ant-menu-dark .ant-menu-inline.ant-menu-sub {
    background: inherit !important;
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
}