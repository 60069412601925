.ant-row {
    background-image: transparent;
    position: relative;
    height: 100% !important;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.login-container {
    padding: 32px 0;
    -ms-flex: 1 1;
    flex: 1 1;
}

@media (min-width: 768px) {
    .login-container {
        padding: 112px 0 24px;
    }
}

.login-top {
    text-align: center;

    >.login-header {
        height: 44px;
        line-height: 44px;

        >a {
            text-decoration: none;

            >.title {
                font-size: 33px;
                color: rgba(0, 0, 0, .85);
                font-weight: 500;
                position: relative;
                top: 2px;
            }
        }
    }

    >.login-desc {
        font-size: 14px;
        color: rgba(0, 0, 0, .45);
        margin-top: 12px;
        margin-bottom: 40px;

        >font {
            vertical-align: inherit;
        }
    }
}

.login-main {
    margin: 0 auto;
}

.login-form-forgot {
    float: right;
}

.login-form-button {
    width: 100%;
}

.login-footer {
    text-align: center;
    font-size: 11px;
}